* {
  font-family: "PT Sans", sans-serif;
}

body {
  margin: 0;
}

.logo {
  position: relative;
  top: 4px;
  height: 25px;
}

.aya {
  color: #3d61ff;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #dddddd;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

iframe {pointer-events: none;}

li {
  float: left;
}

li a {
  display: block;
  color: black;
  text-align: center;
  padding: 12px 24px 14px 24px;
  text-decoration: none;
  font-size: 22px;
}

.featuredVideo {
  width: calc(60vw);
  height: calc(60vw / 16 * 9);
  margin-bottom: 50px;
}

.right {
  float: right;
}

.scroll-point {
  position: absolute;
  top: -42px;
}

html {
  scroll-behavior: smooth;
}

li a:hover:not(.active) {
  color: #f4c430;
}

.active {
  background-color: #f4c430;
}

.emoji-small {
  margin: 0;
  font-size: 70px;
}

.emoji-big {
  margin: 0;
  font-size: 40px;
}

.team-image {
  width: 50%;
  border-radius: 50%;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 10%);
}

.footer {
  width: 100%;
  background-color: #eeeeee;
  margin: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 40px;
}

.footer-image {
  position: relative;
  top: 12px;
  height: 50px;
}

.footer-links {
  color: white;
  margin-left: 10px;
}

h4 {
  padding: 10px 0;
  margin: 0 0 0 20px;
}

h4 > a {
  text-decoration: none;
  color: blue;
}

.footer-link {
  margin-right: 20px;
  color: black;
}

@media only screen and (max-width: 768px) {
  .logo {
    height: 19px;
  }

  ul {
    height: 40px;
    position: absolute;
  }

  .featuredVideo {
    width: calc(80vw);
    height: calc(80vw / 16 * 9);
    margin-bottom: 50px;
  }

  .no-mobile {
    display: none;
  }

  .emoji-small {
    margin: 0;
    font-size: 70px;
  }

  .emoji-big {
    margin: 0;
    font-size: 80px;
    text-align: center;
  }
  .team-image {
    width: 50%;
    border-radius: 50%;
    box-shadow: 10px 10px 10px rgb(0 0 0 / 10%);
  }

  .footer {
    width: 100%;
    background-color: #eeeeee;
    margin: 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h4 {
    padding: 10px 0;
    margin: 0 0 0 20px;
  }

  h4 > a {
    text-decoration: none;
    color: blue;
  }

  .footer-link {
    margin-right: 20px;
    color: black;
  }
}
